<template>
  <div
    v-if="pageData !== null"
    :style="'background-color: '+pageData.backgroundColor+'; min-height: 100vh'"
  >
    <!-- <a id="logo" href="https://alvarotrigo.com/fullPage/" alt="fullPage homepage">Enercred</a> -->
    <div class="text-xs-center" v-if="loading">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <div id="app" v-if="!loading">
      <!-- <ul id="menu" style="background-color:#000"></ul> -->
      <v-container fluid grid-list-md fill-height>
        <v-layout row wrap align-center justify-center fill-height>
          <!-- Web -->
          <v-flex xs12 sm12 md6 class="hidden-sm-and-down align-center justify-center" style="text-align: center; background: #efefef; padding: 3%; min-height: 100vh;">
            <!-- Right WEB -->
            <cpnHeaderWeb class="hidden-sm-and-down"/>
            <v-flex xs12 sm12 md12>
              <div class="header hidden-sm-and-down">
                <span class="display-1 font-weight-bold teal--text">{{user.name.split()[0]}} cadastre seu cartão</span>
                <div class="grey--text darken-4 headline pb-5">
                  Alguma dúvida? Veja porque você deve adicionar seu cartão.
                  <br />
                </div>
                <!-- <cpnFaq/> -->
                <iframe 
                  width="100%" 
                  height="320" 
                  src="https://www.youtube.com/embed/Qa1QUEaklys?start=26&end=66"
                  frameborder="0" 
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen>
                </iframe>
              </div>
            </v-flex>
          </v-flex>
          <!-- Mobile -->
          <v-flex xs12 sm12 md6 class="hidden-md-and-up" style="text-align: center; background: #FFF; padding: 3%;">
            <!-- Header Mobile -->
            <cpnHeaderMobile class="hidden-md-and-up" hasDuvidas="true"/>
            <v-flex xs12 sm12 md12>
              <div class="hidden-md-and-up pt-5">
                <cpnSteps class="pb-3" :steps="{steps: 4, e1: 4}"/>
                <span class="display-1 font-weight-bold teal--text">{{user.name.split(' ')[0]}} cadastre seu cartão</span>
                <div class="gray--text headline pb-1">
                  Alguma dúvida? Veja porque você deve adicionar seu cartão.
                  <!-- <br /> -->
                </div>
                <iframe 
                  width="100%" 
                  height="180" 
                  src="https://www.youtube.com/embed/Qa1QUEaklys?start=26&end=66"
                  frameborder="0" 
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen>
                </iframe>
              </div>
            </v-flex>
          </v-flex>
          <v-flex xs12 sm12 md6 style="text-align: center">
            <div class="form_conta" style="padding-top: 20px">
              <div class style="padding: 0">
                <cpnSteps class="hidden-sm-and-down pb-3" :steps="{steps: 4, e1: 4}"/>
                <div 
                  class="headline hidden-sm-and-down"
                  style="padding-bottom: 15px; color: #666"
                >Cadastrar Cartão de Crédito</div>
                <p class="red--text body">
                  Você só será cobrado após receber os créditos em sua conta de
                  luz.
                </p>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container>
                    <!-- proprietario do cartao -->
                    <v-layout row nowrap>
                      <v-flex xs8>
                        <v-text-field
                          v-model="numero_cartao"
                          outline
                          label="Número do Cartão"
                          name="numero_cartao"
                          type="tel"
                          :rules="[v => !!v || 'Informe o número do cartão']"
                          v-mask="['####-####-####-####', '###-######-#####']"
                          required
                        ></v-text-field>
                      </v-flex>
                      <!-- CVV -->
                      <v-flex xs4>
                        <v-text-field
                          v-model="data_validade"
                          outline
                          label="Validade (MM/AA)"
                          name="data_validade"
                          type="tel"
                          :rules="[
                            v => !!v || 'Informe a data de validade do cartão',
                            v =>
                              v.length === 4 ||
                              'Informe a data de validade do cartão'
                          ]"
                          mask="##/##"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <!-- numero do cartao -->
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-text-field
                          v-model="nome_completo"
                          outline
                          label="Nome como está no cartão"
                          name="proprietario"
                          :rules="[
                            v =>
                              !!v || 'Informe o nome do proprietário do cartão'
                          ]"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <!-- data de validade -->
                    <v-layout row wrap>
                      <v-flex sm6 xs12>
                        <v-text-field
                          v-model="cvv"
                          type="tel"
                          outline
                          label="CVV"
                          v-mask="['###', '####']"
                          name="CVV"
                          :rules="[
                            v =>
                              !!v || 'Informe o código de verificação do cartão'
                          ]"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex sm6 xs12 style="text-align: center" v-if="pageData.logoUrl">
                        <img
                          style="max-width:120px;
                        max-height:70px"
                          :src="pageData.logoUrl"
                        />
                      </v-flex>
                      <v-flex sm6 xs12 style="text-align: center" v-else>
                        <img
                          style="max-width:110px;
                        max-height:60px"
                          src="../../assets/img/cartao/credit-card.svg"
                        />
                        <img
                          style="max-width:110px;
                        max-height:58px"
                          src="../../assets/img/cartao/pagarme_logo.svg"
                        />
                      </v-flex>
                    </v-layout>
                    <div class="mt-2">
                      <v-container>
                        <v-layout row nowrap>
                          <!-- <v-flex xs4>
                            <v-btn
                              block
                              large
                              style="background-color: grey"
                              @click.prevent="pular()"
                            >Pular</v-btn>
                          </v-flex> -->
                          <v-flex xs12>
                            <v-btn
                              block
                              large
                              :style="'background-color:'+ pageData.buttonColor+';'"
                              :loading="processing"
                              @click.prevent="validate()"
                              :disabled="!valid"
                            >CADASTRAR CARTÃO</v-btn>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex xs12 class="caption">
                            Para sua segurança, nós iremos realizar uma pré autorização no valor de R$1,23 no seu cartão.
                            Isso é apenas para verificação e o valor não será debitado da sua conta.
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </div>
                  </v-container>
                </v-form>
                <br />
              </div>
              <!-- SNACKBAR -->
              <v-snackbar v-model="snackbar" top :timeout="2000">
                {{ snackBarText }}
                <v-btn color="red" text @click="snackbar = false">x</v-btn>
              </v-snackbar>
              <!-- PROGRESS CIRCLE -->
              <!-- <v-progress-circular v-if="processing" :size="50" color="teal" indeterminate></v-progress-circular> -->
            </div>
          </v-flex>
          <div class="header hidden-md-and-up pt-2 px-3" style="background-color: #efefef">
            <div class="grey--text py-1" id="faq">Dúvidas</div>
            <cpnFaq/>
          </div>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import { CREATE_CARD } from "../../graphql/cards"
import { mapGetters } from "vuex"
import { saveAnalytics } from "@/services/save_analytics"
// import { setRDConversion } from "@/services/rdstation"
import { SET_CONVERSION } from "../../graphql/rdstation"
import { mask } from "vue-the-mask"
import { firestore } from "../../firebase/firestore"
import HeaderWeb from "../../components/web/Header"
import HeaderMobile from "../../components/mobile/Header"
import Faq from "../../components/faq/Faq"
import Steps from "../../components/core/Steps"

export default {
  components: {
    cpnHeaderWeb: HeaderWeb,
    cpnHeaderMobile: HeaderMobile,
    cpnSteps: Steps,
    cpnFaq: Faq
  },
  data() {
    return {
      valid: false,
      loading: true,
      processing: false,
      nome_completo: "",
      cvv: "",
      numero_cartao: "",
      data_validade: "",
      isProd: false,
      snackBarText: "",
      snackbar: false,
      pageData: null
    }
  },
  directives: { mask },
  created() {
    let url = window.location.origin
    let subdomain = url.slice(url.lastIndexOf("/") + 1).split(".")[0]
    if (subdomain !== "onboard" && subdomain !== "localhost:8080") {
      // get partner page data from firestore
      firestore
        .doc("partners/" + subdomain)
        .get()
        .then(partnerPageData => {
          this.pageData = partnerPageData.data().style.cartao
        })
    } else {
      firestore
        .doc("partners/enercred")
        .get()
        .then(enercredPage => {
          this.pageData = enercredPage.data().style.cartao
        })
    }
    this.isProd = process.env.NODE_ENV === "production"
    if (this.isProd) {
      const analytics = {
        eventCategory: "onboard",
        eventAction: "VIEWED_CARD",
        eventLabel: "VIEWED_CARD",
        value: ""
      }
      saveAnalytics(analytics)
    }
    if(this.$route.query.email) {
      const email = this.$route.query.email
      // console.log('email1: ', email)
      this.getUserByEmail(email)
        .then(users => {
          // console.log('users: ', users)
          if(users.docs.length > 0) {
            const newUser = users.docs[0].data()
            // console.log('newUser', newUser)
            this.$store.commit("storeUserState", newUser)
          } else {
            this.$router.push("/economizar")
          }
          // this.setLead(lead.data.lead)
          this.loading = false
        })
        .catch((error) => {
          console.error('Email não encontrado: ', email + ' - Erro: ' + error)
          this.loading = false
          this.$router.push("/economizar")
        })
    } else {
      if (
        !this.user ||
        this.user.buyer_id === null ||
        this.user.buyer_id === undefined ||
        this.user.customer_id === null ||
        this.user.customer_id === undefined
      ) {
        const user = JSON.parse(localStorage.getItem("newUser"))
        // console.log('user: ', user)
        if(!user) {
          console.error('usuario não identificado!')
          this.$router.push("/economizar")
        }
        this.$store.commit("storeUserState", JSON.parse(localStorage.getItem("newUser")))
        this.loading = false
      } else {
        this.loading = false
      }
    }
   
    // if (
    //   !this.user ||
    //   this.user.buyer_id === null ||
    //   this.user.buyer_id === undefined ||
    //   this.user.customer_id === null ||
    //   this.user.customer_id === undefined
    // ) {
    //   const user = JSON.parse(localStorage.getItem("newUser"))
    //   console.log('user: ', user)
    //   if(!user) {
    //     console.log('usuario não identificado!')
    //     this.$router.push("/economizar")
    //   }
    //   this.$store.commit("storeUserState", JSON.parse(localStorage.getItem("newUser")))
    // }
  },
  computed: {
    user() {
      // console.log('user: ', this.$store.getters["getUser"])
      return this.$store.getters["getUser"]
      // return JSON.parse(localStorage.getItem("newUser"));
    },
    ...mapGetters(["getUser"])
  },

  methods: {
    pular() {
      this.$router.push("bem-vindo")
    },
    async getUserByEmail(email) {
      return await firestore
        .collection('users')
        .where('email','==', email)
        .limit(1)
        .get()
    },
    showError(msg) {
      this.snackBarText = msg
      this.snackbar = true
      this.processing = false
    },

    createCreditCard() {
      let card = {}
      card.card_holder_name = this.nome_completo
      card.card_expiration_date = this.data_validade.replace(/[^\d]/g, "")
      card.card_number = this.numero_cartao.replace(/[^\d]/g, "")
      card.card_cvv = this.cvv

      // eslint-disable-next-line no-undef
      let cardValidations = pagarme.validate({ card: card })
      if (!cardValidations.card.card_number) {
        this.showError("Número do cartão incorreto")
        return
      } else {
        // eslint-disable-next-line no-undef
        pagarme.client
          .connect({
            encryption_key: process.env.VUE_APP_PAGARME_ENCRYPTION_KEY
          })
          .then(client => client.security.encrypt(card))
          .then(card_hash => {
            const newCard = {
              customer_id: this.user.buyer_id.toString(),
              card_hash,
              card_default: true,
              external_id: this.user.customer_id,
              card_bonus: 0
            }
            this.createCard(newCard)
              .then(() => {
                if (this.isProd) {
                  // analytics
                  const analytics = {
                    eventCategory: "onboard",
                    eventAction: "CREATE-NEW-CARD",
                    eventLabel: "CREATE-NEW-CARD",
                    value: this.user.average_consumption
                  }
                  saveAnalytics(analytics)
                  // rd station
                  const conversion = {
                    event_type: "ORDER_PLACED",
                    event_family: "CDP",
                    payload: {
                      name: this.user.name,
                      email: this.user.email,
                      cf_order_id: this.user.cpf,
                      cf_order_total_items: 1,
                      cf_order_status: "credit_card_add",
                      cf_order_payment_method: "Credit Card",
                      cf_order_payment_amount: parseInt(this.user.average_consumption || 0)
                    }
                  }
                  this.setRDConversion(conversion)
                  
                  // Adicionada conversão de Adição de Cartão
                  // rd station
                  const conversion2 = {
                    event_type: "CONVERSION",
                    event_family: "CDP",
                    payload: {
                      conversion_identifier: "add_card",
                      // name: user.name,
                      email: this.user.email,
                      // mobile_phone: this.user.phone ? this.user.phone : "",
                      traffic_source: this.user.utm_source,
                      traffic_medium: this.user.utm_medium,
                      traffic_campaign: this.user.utm_campaign,
                      tags: ["add_card"],
                      available_for_mailing: true,
                      cf_consumo_medio_mensal: this.user.average_consumption,
                      // cf_grupo_consumo_medio_mensal: this.user.average_consumption * 1 > 200 ? "maior 200" : "ate 200",
                      // cf_concessionaria: this.user.concessionaria
                    }
                  }
                  this.setRDConversion(conversion2)
                }
                this.processing = false
                // this.$router.push("cadastro-concluido")
                this.$router.push("/cadastro-boas-vindas")
              })
              .catch((error) => {
                console.error(error.erros)
                this.showError("Erro ao cadastrar cartão")
                return
              })
          })
          .catch((error) => {
            console.error(error.erros)
            this.showError("Erro ao cadastrar cartão")
            return
          })
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.processing = true
        this.createCreditCard()
      }
    },
    createCard(card) {
      return this.$apollo.mutate({
        // Query
        mutation: CREATE_CARD,
        // Parameters
        variables: {
          customer_id: card.customer_id,
          card_hash: card.card_hash,
          card_default: card.card_default,
          external_id: card.external_id,
          card_bonus: card.card_bonus
        }
      })
    },
    setRDConversion(conversion) {
      return this.$apollo.mutate({
        // Query
        mutation: SET_CONVERSION,
        // Parameters
        variables: {
          event_type: conversion.event_type,
          event_family: conversion.event_family,
          payload: conversion.payload
        }
      })
    }
  },
  mounted() {
    this.loading = false
  }
}
</script>

<style scoped>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
.headline {
  font-size: 1.2em !important;
}
fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.caption {
  font-size: 14px !important;
  color: #666;
}
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  font-family: arial, helvetica;
  height: 100%;
  background-color: #095f96;
}
template {
  background-color: #095f96;
}
.area {
  height: 100px;
  width: 100px;
  background: red;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height: 100px;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.header {
  font-size: 1.5em;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
}
.header span {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 4em;
  color: #fff;
  font-weight: bold;
}
.form_conta {
  height: 100%;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 10% 5% 2% 5%;
  text-align: center;
  border-radius: 10px;
}
.step2_esquerda {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}
/*
.form_class {
  padding-top: 100px;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  text-align: center;
}
*/
.section {
  position: relative;
  text-align: center;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 900;
}
#section-1 h1 {
  font-size: 2em;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-1 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-1-button {
  padding: 0.93em 1.87em;
  background: #00a8bb;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 500;
}
#section-3 h1 {
  font-size: 2em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-3 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-3-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-3 .intro {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding: 0 3em;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.atencao {
  font-size: 1.5em;
  color: #00a8bb;
  font-weight: bold;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i {
  vertical-align: middle;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 38px;
  height: 14px;
  color: white;
  top: -4px;
  left: -2px;
  fill: #fff;
}
.twitter-share svg {
  height: 40px;
  margin-top: -10px;
}
.view-github {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}

.shell {
  width: calc(50% - 0px);
  display: inline-block;
  background-color: #fff;
  /*  background-image: url('../assets/eolica.png'); */
  background-position: center;
  border-radius: 0px 0px 0 0;
  text-align: left;
  margin-right: 0px;
}
</style>
